const priceListData = [
  {
    id: 1,
    label: "տեստ",
    childs: [
      {
        label: " 86.90  Լաբորատորիա կլինիկական",
        childs: [
          {
            label: "Արյան ընդհանուր անալիզ առանց լեյկոֆորուլայի",
            value: "1000 ",
          },
          { label: "Արյան ընդհանուր անալիզ լեյկոֆորուլայով", value: "3000 " },
          { label: "Մեզի ընդհանուր անալիզ", value: "2000 " },
          { label: "PSA հետազոտություն", value: "2000 " },
          {
            label: "Կղանքի ընդհանուր հետազոտություն /կոպրոլոգիական/",
            value: "2000 ",
          },
          {
            label: "Կղանքի ընդհանուր հետազոտություն ճճվակրության վերաբերյալ",
            value: "2000 ",
          },
          { label: "Խորխի ընդհանուր հետազոտություն", value: "2000 " },
          { label: "Արյան խմբի և ռեզուս գործոնի որոշում", value: "2000 " },
          {
            label: "Ուրոգենիտալ քսուքի հետազոտում /գինեկալոգիա/",
            value: "2000 ",
          },
          {
            label: "Մաշկի հետազոտում սնկային հիվանդության հայտնաբերման համար",
            value: "2000 ",
          },
          { label: "Արյան RPR հետազոտություն /MRP/", value: "1000 " },
          {
            label: "Արգանդի պարանոցի բջջաբանական հետազոտություն",
            value: "3000 ",
          },
          { label: "HBsAg-ի որոշում /հեպատիտ B/", value: "2000 " },
          { label: "ԱՍԼՕ-ի որոշում արյան մեջ", value: "1000 " },
          { label: "Գլյուկոզայի որոշում արյան մեջ", value: "1000 " },
          { label: "Պրտրոմբինային ինդեքսի որոշում", value: "1000 " },
          { label: "C-ռեակտիվ սպիտակուցի որոշում", value: "1000 " },
          { label: "Ընդհանուր սպիտակուցի որոշում", value: "1000 " },
          { label: "Խոլեստերինի որոշում", value: "1000 " },
          { label: "Բիլիռուբինի որոշում արյան մեջ", value: "1000 " },
          { label: "աչքի թարթիչի հետազոտութ / Դեմոդեքս/", value: "2000 " },
          { label: "Ռևմատոիդ ֆակտորի որոշում", value: "1000 " },
          { label: "Միզանյութի որոշումը արյան մեջ", value: "1000 " },
          { label: "Միզաթթվի որոշումը արյան մեջ", value: "1000 " },
          { label: "ԱԼԱՏ ֆերմենտի որոշում", value: "1000 " },
          { label: "ԱՍԱՏ ֆերմենտի որոշում", value: "1000 " },
          { label: "Հիմնային ֆոսֆատազայի որոշում", value: "1000 " },
          { label: "Ca-ի քանակի որոշում արյան մեջ", value: "1000 " },
          { label: "Կրեատինինի որոշում արյան մեջ", value: "1000 " },
          { label: "Տրիգլիցերիդների որոշում", value: "1000 " },
          {
            label: "L ամիլազայի որոշում արյան մեջ /ALFA ամիլազա/",
            value: "1000 ",
          },
          { label: "Խլամիդիայի որոշում արյան մեջ", value: "4500 " },
          { label: "Ցիտոմեգալովիրուսի որոշում արյան մեջ", value: "4500 " },
          { label: "Ազատ թիրոքսինի որոշում Tufree", value: "4000 " },
          { label: "Անտիթիրոիդ պերօքսիդազայի որոշոիմ /ТПO", value: "5000 " },
          { label: "C-պեպտիդ", value: "4000 " },
          { label: "Հեմոլիզացված հեմոգլոբին", value: "4000 " },
          { label: "Վահանաձև գեղձի հակամարմիններ /ATG", value: "5000 " },
          { label: "ՈՒրեոպլազմայի որոշում", value: "4500 " },
          { label: "TTG, TSH հետազոտություն", value: "4000 " },
          { label: "ՁԻԱՀԻ հետազոտություն", value: "2000 " },
          { label: "Հեպատիտ C հետազոտություն /HCV", value: "2000 " },
          { label: "HDL Խալեստիրինի /բարձր խտություն/", value: "1000 " },
          { label: "LDL Խալեստիրինի /ցածր խտություն/", value: "1000 " },
          { label: "Վիտամին Դ հետազոտություն 86.90", value: "8000 " },
          { label: "Covid 19 հակամարմինների որոշում", value: "6000 " },
          { label: "A Ստրեպտակոկի հակածին strep-test", value: "3000 " },
          { label: "Ֆիբրոգեն", value: "1000 " },
          { label: "Մակարդելիություն", value: "1000 " },
          { label: "Ազատ /կապված բիլիռուբինի որոշում", value: "1000 " },
          { label: "Ալբումին", value: "1000 " },
          { label: "Բրուցելյազի որոշում", value: "2000 " },
          {
            label: "Հետազոտություն աղիքների բացիլակրության վերաբերյալ",
            value: "2000 ",
          },
          {
            label:
              "Կլինիկական և այլ ախտաբանական նյութի /թարախ, մեզ, խորխ, պունկտատ, կրծքի կաթ և այլն / հետազոտություն առանց հակաբիոտիկների զգայնության որոշում/",
            value: "2000 ",
          },
          { label: "Հակաբիոտիկների զգայնության որոշում", value: "3000 " },
          { label: "Դիսբակտերիոզ հետազոտություն", value: "5000 " },
        ],
      },
      {
        label: "86.22 Միջամտությունների կաբինետ",
        childs: [
          { label: "Միջմկանային ներարկում", value: "500" },
          { label: "Ներերակային ներարկում", value: "1000" },
          { label: "Բուժման կուրս մ/մ 10 ներարկում", value: "4000" },
          { label: "Բուժման կուրս ն/մ 10 ներարկում", value: "7000" },
        ],
      },
      {
        label: "86.90  Ֆլյուորոգրաֆիա",
        childs: [{ label: "Մեկ նկարահանում", value: "1500 " }],
      },
      {
        label: "86.90  Ռենտգեն ծառայություն",
        childs: [
          {
            label: "Ռենտգենոգրաֆիա /մեկ նկարի/ 30 x 40 ժապավեն",
            value: "5000",
          },
          {
            label: "Ռենտգենոգրաֆիա /մեկ նկարի/ 18 x 24, 24 x 30 ժապավեն",
            value: "4000",
          },
          {
            label: "Ռենտգենոգրաֆիա /մեկ նկարի/ 13 x 18 ժապավեն",
            value: "3000",
          },
          { label: "Ռենտգենոսկոպիա", value: "3000" },
          { label: "Կերակրափողի կոնտրաստային հետազոտություն", value: "5000" },
          {
            label: "Ստամոքսաաղիքային տրակտի կոնտրաստային հետազոտություն",
            value: "15000",
          },
          { label: "Ստամոքսի կոնտրաստային հետազոտություն", value: "8000" },
          {
            label: "Կերակրափողի - Ստամոքսի կոնտրաստային հետազոտություն",
            value: "10000",
          },
        ],
      },
      {
        label: "86.90  Ուլտրաձայնային հետազոտություն",
        childs: [
          { label: "Որովայնի խոռոչ", value: "6000" },
          { label: "Փոքր կոնքի խոռոչ", value: "6000" },
          { label: "Վահանաձև գեղձի", value: "5000" },
          { label: "Պտղի սեռի որոշում", value: "5000" },
          { label: "Հոդերի ուլտրաձայնային հետազոտություն", value: "8000" },
          {
            label: "Փափուկ հյուսվածքի ուլտրաձայնային հետազոտություն",
            value: "6000",
          },
          {
            label: "Լիֆանտիկ հանգույց ուլտրաձայնային հետազոտություն",
            value: "6000",
          },
          { label: "Ֆոլիկուլոմետրիա", value: "8000" },
          { label: "Ներվագինալ ուլտրաձայնային հետազոտություն", value: "7000" },
          { label: "Ամորձիների ուլտրաձայնային հետազոտություն", value: "10000" },
          { label: "Թքագեղձի ուլտրաձայնային հետազոտություն", value: "5000" },
          {
            label: "Կրծքագեղձի գործիքային հետազոտություն /պրեստլայթ/",
            value: "6000",
          },
        ],
      },
      {
        label: "86.22   Սրտաբանական",
        childs: [
          { label: "Բժշկի խորհրդատվություն /մեծահասակ", value: "5000" },
          { label: "Բժշկի խորհրդատվություն /մանկական", value: "3000" },
          { label: "ԷՍԳ", value: "2000" },
        ],
      },
      {
        label: "86.22  Վիրաբույժական ծառայություն",
        childs: [
          {
            label: "Բժշկի խորհրդատվություն /մեծահասակ",
            value: 5000,
          },
          {
            label: "Բժշկի խորհրդատվություն //մեծահասակ",
            value: 3000,
          },
          {
            label: "Փոքր վիրակապ",
            value: 1000,
          },
          {
            label: "Մեծ վիրակապ",
            value: 2000,
          },
          {
            label: "Գիպսային վիրակապ",
            value: 5000,
          },
          {
            label: "Վիրահատություն/փոքր միջամտություն/",
            value: 5000,
          },
        ],
      },
      {
        label: "86.22  Քիթ – կոկորդ-ականջաբանական ծառայություն",
        childs: [
          {
            label: "Բժշկի խորհրդատվություն /մեծահասակ/",
            value: 5000,
          },
          {
            label: "Բժշկի խորհրդատվություն /մանկական/",
            value: 3000,
          },
          {
            label: "Կրկնակի խորհրդատվություն",
            value: 2000,
          },
          {
            label: "Թմկաթաղանթի պնեմոմասաժ",
            value: 1000,
          },
          {
            label: "Լսողական փողերի փչում ըստ պոլիտների և պնեմոմասաժ",
            value: 2000,
          },
          {
            label: "Խցանի հեռացում",
            value: 5000,
          },
          {
            label: "Ականջի լվացում",
            value: 2000,
          },
          {
            label: "Արտաքին լսողական անցուղու վակում արտածծում",
            value: 3000,
          },
          {
            label: "Հարակից խոռոչների վակում արտածծում",
            value: 3000,
          },
          {
            label: "Կիսելբախի հանգույցի այրում",
            value: 3000,
          },
          {
            label: "Կիսելբախի հանգույցի կրկնակի արյում",
            value: 2000,
          },
          {
            label: "Քմային նշիկների լակունաների լվացում",
            value: 3000,
          },
          {
            label: "Դեղորայքի ներկոկորդային լցում/1 պրոցեդուրա/",
            value: 1000,
          },
          {
            label: "Հարքթային խոռոչներում ուլտրաձայնային հետազոտ./86.90/",
            value: 5000,
          },
          {
            label: "Հարքթային խոռոչներում կրկնակի ուլտրաձ.հետազոտ./86.90/",
            value: 3000,
          },
          {
            label: "Բկանցքի լորձաթաղանթի դեղորայքային քսում",
            value: 1000,
          },
          {
            label: "ԼՕՌ օրգանների ֆիբրոսկոպիա",
            value: 15000,
          },
        ],
      },
      {
        label: "86.22    Ակնաբուժական ծառայություն",
        childs: [
          {
            label: "Բժշկի խորհրդատվություն /բիոմիկրոսկոպիա, օֆտալմոսկոպիա/",
            value: 5000,
          },
          {
            label: "Ակնաբուժի խորհրդատվություն /մանկական/",
            value: 3000,
          },
          {
            label: "Ակնոցների որոշում",
            value: 2000,
          },
          {
            label: "Տոնոմետրիա",
            value: 2000,
          },
          {
            label: "Օտար մարմնի հեռացում",
            value: 5000,
          },
          {
            label: "Պարապուլբար ներարկում",
            value: 1500,
          },
          {
            label: "Ավտոռեֆրակտոկեռատոմետրիա",
            value: 3000,
          },
          {
            label: "Ոչ կոնտակտային աչքի տոնոմետրիա",
            value: 2000,
          },
          {
            label: "Պերիմետրիա՝ տեսադաշտի ստուգում",
            value: 10000,
          },
        ],
      },
      {
        label: "86.90      Ֆիզիոթերապիա",
        childs: [
          {
            label: "Ֆիզիոթերապևտի խորհրդատվություն",
            value: 5000,
          },
          {
            label:
              "Լուսաբանություն, UBЧ, ինգալացիա, դարսենվալ, ուլտրաձայն 1 պրոցեդուրա",
            value: 1500,
          },
          {
            label: "Ինհալացիոն բուժում՝ նեբուլայզերի միջոցով /1 օր/տնային",
            value: 600,
          },
          {
            label: "Մագնիտո լազերային թերապիա խրոնիկ տոնզիլիտ /մանկական/",
            value: 2000,
          },
          {
            label: "Մագնիտո լազերային թերապիա /մեծահասակներ/",
            value: 3000,
          },
          {
            label: "Ադենոիտներ, Պանսինուսներ/1 պրոցեդուրան/",
            value: 3000,
          },
          {
            label: "Էքսուդատիվ օպիտ/1 պրոցեդուրան/",
            value: 3000,
          },
          {
            label: "Օստեոխոնդրոզ, Արթրոզ, Շպորներ/1 պրոցեդուրան/",
            value: 3000,
          },
          {
            label: "Պարաֆինի պրոցեդուրա",
            value: 1500,
          },
        ],
      },
      {
        label: "86.21      Գինեկոլոգիա",
        childs: [
          {
            label: "Բժշկի խորհրդատվություն, քսուք, ցիտոլոգիա",
            value: 5000,
          },
          {
            label: "Արգանդի վզիկի էռոզիայի բուժում, այրում",
            value: 10000,
          },
          {
            label: "Հեշտոցային օղակի տեղադրում",
            value: 5000,
          },
          {
            label: "Ներարգանդային գալարի տեղադրում",
            value: 10000,
          },
          {
            label: "Ներարգանդային գալարի հեռացում",
            value: 5000,
          },
          {
            label: "Միանվագ կոմպլեքս բուժում",
            value: 20000,
          },
          {
            label: "Միանվագ կոմպլեքս բուժում /հեշտոցի սանացիա/",
            value: 10000,
          },
          {
            label: "Կոլկոսկոպիա",
            value: 5000,
          },
          {
            label: "Հղիի հաշվառում /տեղամասից դուրս/",
            value: 40000,
          },
          {
            label: "Պապիլոմաների հեռացում/արգանդի պարանոցի/1 էլեմենտ",
            value: 5000,
          },
          {
            label: "Պապիլոմայի հեռացում/մեծ մակերես/",
            value: 35000,
          },
          {
            label: "Արգանդի պարանոցի էկտոպիա/փոքր/",
            value: 20000,
          },
          {
            label: "Արգանդի պարանոցի էկտոպիա/մեծ/",
            value: 25000,
          },
        ],
      },
      {
        label: "86.22      բուժական ֆիզկուլտուրա",
        childs: [
          {
            label: "Բուժական ֆիզկուլտուրա /մեծ/",
            value: 1500,
          },
          {
            label: "Բուժական ֆիզկուլտուրա /փոքր/",
            value: 1200,
          },
        ],
      },
      {
        label: "86.22      Մաշկավեներոլոգ",
        childs: [
          {
            label: "Մաշկավեներոլոգ խորհրդատվություն մեծահասակ",
            value: 5000,
          },
          {
            label: "Մաշկավեներոլոգ խորհրդատվություն մանկական",
            value: 3000,
          },
          {
            label: "Կոնտակտային մաշկաբորբեր/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "Պիոդերմիա/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "ալերգիկ մաշկաբորբ/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "վիրուսային մաշկախտ/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "սնկային հիվանդություններ/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "Փսորիազ/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "Էկզեմա/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "Տոքսիկոդերմիաներ/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "Կորյակային հիվանդություններ/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "Ռոզացեա/ախտորոշում և բուժում/",
            value: 10000,
          },
          {
            label: "Փափուկ ֆիբրոմաներ - 1 էլեմենտը",
            value: 1000,
          },
          {
            label: "գորտնուկների հեռացում - 1 էլեմենտը/ցավազրկումով/",
            value: 5000,
          },
          {
            label: "Սեռավարակների ախտորոշում և բուժում",
            value: 20000,
          },
        ],
      },

      {
        label: "86.22      ՈՒռոլոգիա",
        childs: [
          {
            label: "ՈՒռոլոգի խորհրդատվություն /մեծահասակ/",
            value: 5000,
          },
          {
            label: "ՈՒռոլոգի խորհրդատվություն /մանկական/",
            value: 3000,
          },
          {
            label: "Սուր ցիստիտ",
            value: 10000,
          },
          {
            label: "Քրոնիկ ցիստիս",
            value: 15000,
          },
          {
            label: "Վերին և ստորին միզուղիների քրոնիկ բորբոքում",
            value: 15000,
          },
          {
            label: "Սուր պրոստատիտ",
            value: 20000,
          },
          {
            label: "Քրոնիկ պրոստատիտ /ներառյալ շագանակագեղձի մերսում/",
            value: 30000,
          },
          {
            label: "Սուր և քրոնիկ օրխոէպիդիդիմիտ",
            value: 20000,
          },
          {
            label: "Միզաքարային հիվանդություն",
            value: 10000,
          },
          {
            label: "Շագանագեղձի ադենոմիա /կոնսերվատիվ բուժում/",
            value: 10000,
          },
          {
            label: "Կանացի անմիզապահության ախտորոշում, բուժում",
            value: 15000,
          },
          {
            label: "Ուրետրալ կաթետրի ախտորոշում, բուժում",
            value: 10000,
          },
          {
            label: "Ոչ սպեցեֆիկ ուրեթրիա  ախտորոշում և բուժում",
            value: 15000,
          },
          {
            label: "Ռետրոգրադ ուրետերոգրաֆիա",
            value: 15000,
          },
        ],
      },

      {
        label:
          "Տիգրան Մեծ ԱԿ ՓԲԸ-ում գործում են բժշկական ծառայությունների հետևյալ գներ",
        childs: [
          {
            label: "Օտարերկրյա քաղաքացիների խորհրդատվություն /մանկական/",
            value: 5000,
          },
          {
            label: "Օտարերկրյա քաղաքացիների խորհրդատվություն /մեծահասակ/",
            value: 10000,
          },
          {
            label: "Կանխարգելիչ պատվաստումների քարտ/ձեվ 063/",
            value: 6000,
          },
          {
            label: "Առողջական վիճակի նախնական և պարբերական բժշկական զննություն",
            value: 7000,
          },
          {
            label: "Առողջական վիճակի նախնական և պարբերական բժշկական զննություն",
            value: 6000,
          },
          {
            label: "Առողջական վիճակի նախնական և պարբերական բժշկական զննություն",
            value: 2000,
          },
          {
            label:
              "Առողջական վիճակի նախնական և պարբերական բժշկական զննություն /առանց ֆլյուորոգրաֆիայի և HBS անտիգենի/",
            value: 4000,
          },
          {
            label:
              "Առողջական վիճակի նախնական և պարբերական բժշկական զննություն /առանց ֆլյուորոգրաֆիայի/",
            value: 5000,
          },
          {
            label:
              "Ոչ պետական դպրոցների, մանկավարժների, միջին մասնագիտական, ԲՈԻՀ-երի դասախոսների պարբերական բժշկական զննություն",
            value: 2500,
          },
          {
            label: "Երեխային տրվող քաղվածք առողջության վերաբերյալ /այլ տարածք/",
            value: 5000,
          },
          {
            label: "ցերեկային ստացիոնար ծառայություն /1օր/",
            value: 5000,
          },
          {
            label: "ցերեկային ստացիոնար ծառայություն /10օր/",
            value: 45000,
          },
          {
            label:
              "Բժշկական եզրակացության տրամադրում՝ զենք ձեռք բերելու թույլտվություն /տեղամասի բնակիչ/",
            value: 20000,
          },
          {
            label:
              "Բժշկական եզրակացության տրամադրում՝ զենք ձեռք բերելու թույլտվություն /այլ տեղամասի բնակիչ/",
            value: 25000,
          },
          {
            label:
              "Բժշկական եզրակացության տրամադրում՝ Դատախազի պաշտոնում նշանակմանը խոչընդոտող ֆիզիկական արատների և հիվանդությունների բացակայության վերաբերյալ",
            value: 20000,
          },
          {
            label:
              "Բժշկական եզրակացության տրամադրում՝ Դատավորի պաշտոնում նշանակմանը խոչընդոտող ֆիզիկական արատների և հիվանդությունների բացակայության վերաբերյալ",
            value: 20000,
          },
          {
            label:
              "Բժշկական եզրակացության տրամադրում՝ հատուկ քննչական և քննչական կոմիտեի ծառայողի պաշտոնում նշանակմանը խոչընդոտող ֆիզիկական արատների և հիվանդությունների բացակայության վերաբերյալ",
            value: 20000,
          },
          {
            label: "Որդեգրման համար առողջական վիճակի մասին տրամադրվող տեղեկանք",
            value: 15000,
          },
          {
            label:
              "Հանրային ծառայության պաշտոնն զբաղեցնելու դեպքում լիազորությունների իրականացմանը խոչընդոտող հիվանդությունների մասին տրամադրվող տեղեկանք",
            value: 20000,
          },
          {
            label: "Փրկարար ծառայության ծառայողների պրոֆ զննում",
            value: 25000,
          },
          {
            label:
              "Հայաստանի Հանրապետությունում ժամանակավոր կամ մշտական կացության կարգավիճակ ստանալու համար դիմած անձի առողջական վիճակի վերաբերյալ բժշկական եզրակացություն տրամադրելու նպատակով իրականացվող նեղ մասնագիտական խորհրդատվությունները և անհրաժեշտ լաբորատոր-գործիքային ախտորոշիչ հետազոտություններ",
            value: 15000,
          },
          {
            label:
              "Արտադրական միջավայրում և աշխատանքային գործընթացի վնասակա և վտանգավոր գործոնների ազդեցությանը ենթարկվող բնակչության առանձին խմբերի առողջության վիճակի պարտադիր նախնական և պարբերական բժշկական զննություն",
            value: 8000,
          },
          {
            label:
              "Առողջական վիճակի հետ կապված տեղեկանքի տրամադրում Դատական կարգադրիչ",
            value: 20000,
          },
          {
            label:
              "բժշկական եզրակացության տրամադրում հակակոռուպցիոն կոմիտեի օպերատիվ հետախուզական ծառայության",
            value: 25000,
          },
          {
            label:
              "Վարորդի թեկնածուի /վարորդի առողջական վիճակի վերաբերյալ բժշկական հավաստագրման տեղեկանք /թերապևտ 1 նեղ մասնագետ",
            value: 8000,
          },
          {
            label:
              "Վարորդի թեկնածուի /վարորդի առողջական վիճակի վերաբերյալ բժշկական հավաստագրման տեղեկանք /թերապևտ և 3 նեղ մասնագետ",
            value: 12000,
          },
          {
            label:
              "Վարորդի թեկնածուի /վարորդի առողջական վիճակի վերաբերյալ բժշկական հավաստագրման տեղեկանք /թերապևտ և 4 նեղ մասնագետ",
            value: 14000,
          },
          {
            label:
              "Վարորդի թեկնածուի /վարորդի առողջական վիճակի վերաբերյալ բժշկական հավաստագրման տեղեկանք /թերապևտ և 45 նեղ մասնագետ",
            value: 16000,
          },
          {
            label:
              "Վարորդի թեկնածուի /վարորդի առողջական վիճակի վերաբերյալ բժշկական հավաստագրման տեղեկան",
            value: 20000,
          },
          {
            label:
              "Վարորդի թեկնածուի /վարորդի առողջական վիճակի վերաբերյալ բժշկական հավաստագրման տեղեկանք/ թերապևտ",
            value: 6000,
          },
          {
            label:
              "Վարորդի թեկնածուի /վարորդի առողջական վիճակի վերաբերյալ բժշկական հավաստագրման տեղեկանք /թերապևտ և 2 նեղ մասնագետ",
            value: 10000,
          },
          {
            label:
              "Տնային պայմաններում անձի առողջության վիճակի հաստատման կամ բացառման փորձաքննության իրականացման և փորձաքննության արդյունքում եզրակացության տրամադրում",
            value: 30000,
          },
          {
            label:
              "Ուսումնական հաստատություններ ընդունվողների համար՝ բժշկական տեղեկանք ընդհանուր առողջական վիճակի մասին",
            value: 10000,
          },
          {
            label: "Հոգեբանական հետազոտություն մեծահասակ/ ախտորոշում/",
            value: 6000,
          },
          {
            label: "Հոգեբանական խորհրդատվություն",
            value: 5000,
          },
          {
            label: "Մանկական հոգեբանական խորհրդատվություն",
            value: 4000,
          },
          {
            label: "Գաստրոէնտերոլոգ խորհրդատվություն",
            value: 5000,
          },
          {
            label: "Գաստրոսկոպիա - Բիոպսիա",
            value: 10000,
          },
          {
            label: "Նյարդաբանի խորհրդատվություն",
            value: 5000,
          },
          {
            label: "Նյարդաբանի խորհրդատվություն /մանկական/",
            value: 3000,
          },
          {
            label: "Ինֆեկցիոնիստի խորհրդատվություն",
            value: 5000,
          },
          {
            label: "Ինֆեկցիոնիստի խորհրդատվություն /մանկական/",
            value: 3000,
          },
          {
            label: "Ներզատաբանի խորհրդատվություն",
            value: 5000,
          },
          {
            label: "Դոպլեր /հետազոտություն/",
            value: 2000,
          },
          {
            label: "Սպիրոմետրիա",
            value: 5000,
          },
          {
            label: "Սրտի ուլտրաձայնային հետազոտություն",
            value: 8000,
          },
          {
            label: "Ստորին վերջույթների անոթների դուպլեքս հետազոտություն",
            value: 12000,
          },
          {
            label: "Վերջին վերջույթների անոթների դուպլեքս հետազոտություն",
            value: 10000,
          },
          {
            label: "Վերին /պարանոցի/ անոթների դուպլեքս հետազոտություն",
            value: 12000,
          },
          { label: "Բժշկի տնային կանչ /մանկաբույժ", value: "5000" },
          { label: "Բժշկի տնային կանչ /թերապևտ", value: "5000" },
          { label: "Նեղ մասնագետի խորհրդատվություն /մեծ", value: "5000" },
          { label: "Նեղ մասնագետի խորհրդատվություն /մանկական", value: "3000" },
          { label: "Տեղամասային թերապևտի խորհրդատվություն", value: "5000" },
          { label: "Օտարերկրյա քաղաքացիների տնային կանչ", value: "10000" },
          { label: "Տեղամասային մանկաբուժի խորհրդատվություն", value: "3000" },
        ],
      },
      {
        label: "Սուրդոլոգիական կաբինետ",
        childs: [
          {
            label: "Տոնալ շեմային աուդիոմետրիա",
            value: 5000,
          },
          {
            label: "Իմպենդանսոմետրիա /ռեֆլեքսոմետրիա, տիմպանոմետրիա/",
            value: 5000,
          },
          {
            label: "Տոնալ շեմային աուդիոմետրիա, իմպենդանսոմետրիա",
            value: 8000,
          },
          {
            label: "Տիմպանոմետրիա",
            value: 3000,
          },
          {
            label: "Ռեֆլեքսոմետրիա",
            value: 3000,
          },
          {
            label: "Վիլյամսի թեստ /եվստախյան փողերի անցանելիության որոշում/",
            value: 3000,
          },
        ],
      },
      {
        label: "Կրկնակի հետազոտություն",
        childs: [
          {
            label: "Տոնալ շեմային աուդիոմետրիա",
            value: 3000,
          },
          {
            label: "Իմպենդանսոմետրիա /ռեֆլեքսոմետրիա, տիմպանոմետրիա/",
            value: 3000,
          },
          {
            label: "Տոնալ շեմային աուդիոմետրիա, իմպենդանսոմետրիա",
            value: 5000,
          },
          {
            label: "Տիմպանոմետրիա",
            value: 2000,
          },
          {
            label: "Ռեֆլեքսոմետրիա",
            value: 2000,
          },
          {
            label: "Վիլյամսի թեստ",
            value: 2000,
          },
        ],
      },
    ],
  },
];
export default priceListData;
